<template>
  <cookie-consent-banner
    v-model="cookieConsent"
    :publishing="inProgress"
    @publish="saveCookieConsentBanner"
  />
</template>

<script>
import CookieConsentBanner from "../../../components/Project/CookieConsent/CookieConsentBanner";

export default {
  name: "CookieConsentBannerView",
  components: {
    CookieConsentBanner,
  },
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    cookieConsent: null,
    inProgress: false,
  }),
  methods: {
    async saveCookieConsentBanner() {
      this.inProgress = true;
      try {
        const cookieConsent = await this.$axios.post(
          `/api/v1/business/cookie-consent`,
          {
            params: this.cookieConsent.params,
          }
        );

        this.$event(`cookie_consent_created`);

        this.cookieConsent = cookieConsent;

        if (typeof this.onSuccess === "function")
          return this.onSuccess(cookieConsent);

        this.$router.push({
          name: "cookie_consent",
          params: {
            ...this.$route.params,
            uuid: cookieConsent.uuid,
          },
        });

        this.inProgress = false;
      } catch (e) {
        if (e.showError !== false) {
          this.$toasted.error("Error", {
            icon: "mdi-close-octagon-outline",
          });

          this.error = e.response?.data?.message;
          this.showErrorModal = true;
        }

        this.inProgress = false;

        this.$log.notify(e);
      }
    },
    // ...mapMutations("projects", ["UPDATE_PROJECT_COOKIE_CONSENT"]),
  },
};
</script>
